.commentitem{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding-top: 5px;
    font-style: italic;
    font-size: 15px;
    height: 100px;
    width:100%;
    overflow-y:auto;
   

    
}
p {margin-bottom: .01em;  margin-top: 0.1em;}
