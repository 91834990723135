.projectList{
position:fixed;
top:15vh;

display:flex;
flex-direction: column;
justify-content:flex-start;
align-items:flex start;
width:100%;
height:85vh;
overflow-y: scroll;
bottom:50px;
}