.projectspage{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    background-color: #d2cecc;
    
}



