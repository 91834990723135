.searchselectsdiv{
    padding:0;
    margin:0;
    position:fixed;
    top:8vh;
    width:100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    padding-left:10px;
    
    
    z-index:999;
}