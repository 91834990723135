.displayprojectsdiv{
    position:fixed;
    top:8vh;
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-evenly;
    font-size:20px;
    z-index:999;
}



.displayprojecttableheaders{
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    font-size:23px;
    font-weight: 700;
    z-index:999;
  
    border-radius:10px;
    margin-left:30px;
    margin-right:30px;
    padding-top: 5px;
    padding-bottom:5px;
}
