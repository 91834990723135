.userdashboard{
    display:flex;
    flex-direction:column;
    align-items:center;
    position:relative;
    height:100vh;
    width:100%;
    /* top:10vh */
   
}
.headerNav{

    width:100vw;
    height:10vh;
}