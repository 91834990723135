.displayprojectList{
position:relative;
top:12vh;
display:flex;
flex-direction: column;
justify-content:flex-start;
align-items:flex start;
width:100%;
height:88vh;
overflow-y:auto;

}