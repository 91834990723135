.auditprojectspage{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    /* background-color: #d2cecc; */
    background:radial-gradient(circle at 100%, #93a0aa , #d2cecc 20%, #eee 50%, #93a0aa 90%);
    
}
.projectList{
/* position:fixed; */
display:flex;
flex-direction: column;
justify-content:flex-start;
align-items:flex start;
width:100%;
height:85vh;
overflow-y: scroll;
bottom:50px;
}