.viewMeetingsPage{
display:flex;
flex-direction: row;

}
.dateMenu{
    display:flex;
flex-direction: column;
    width:10%;
    height:89vh;
    padding-left: 10px;
    overflow-y: auto;    
}
.meetingContents{
display:flex;
margin-left: 10px;
width:75%;
flex-direction: column;

}
.dateList{
    display:flex;
flex-direction: column;
}
.contentsOfMeeting{
border:none;
background-color:#f5f5f5 ;
border-radius: 10px;
padding:10px;
margin: 10px;
}