ul{
    list-style: none;
}

.projectItem{
    
    height:60px;
    border:none;
    cursor: pointer;
    display:flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: left;
    gap:10px;
    border-radius: 12px;
    padding-left: 10px;
    padding-right:10px;
    margin:2px 5px 2px 5px;
    background-color:#b7ada7;
   box-shadow: 5px 5px 5px #584538;
   &:hover{
    color:#0a0a0a;
    background-color: #d2cecc;
    transition: all .25s ease 0s;
   }
   &:active{
    box-shadow: inset 0 0 0 4px rgba(0,0,0,0.2);
   }
   
   

}


