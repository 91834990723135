.userdash{

    position:relative;
    /* padding-top:10vh; */
    /* padding-bottom:10vh; */
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    width:100%;
    height:100%
  
   
}
.sectionhor{
    width:25vw;
      
}
.sectionvert{
    height:50%;
    padding-bottom: 10px;

      
}
.upcomingproj{
    height:50%;
    overflow-y: scroll;
      
}