.displayprojectspage{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    background-color: #d2cecc
    
    
}
.projectList{
/* position:fixed; */
display:flex;
flex-direction: column;
justify-content:flex-start;
align-items:flex start;
width:100%;
height:85vh;
overflow-y: scroll;
bottom:50px;
}
.displaysplit{
    display:flex;
    flex-direction:row;
    width:100%;
}