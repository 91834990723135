.auditprojectsdiv{
    position:fixed;
    top:15vh;
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items:center;
    font-size:20px;
    z-index:999;
}

.auditprojaddandtitle{
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    font-size:20px;
    z-index:999;
}

.projecttableheaders{
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items:center;
    font-size:20px;
    z-index:999;
}
.columntitle{
    font-weight:bold;
    display:flex;
    justify-content:center;
}