
.taskitem{
  color:#173A5E;
  height:225px;
  width:90vw;
    border:none;
    cursor: pointer;
    display:flex;
    flex-direction: row;
    /* align-items: flex-start; */
    /* justify-content: left; */
    gap:10px;
    border-radius: 12px;
    /* padding-left: 0px;
    padding-right:0px; */
    margin:00px 20px 20px 40px;
    background-color:#b3c4c2;
   box-shadow: 5px 5px 5px#496361;
   &:hover{
    color:#0a0a0a;
    background-color: #d1dbda;
    transition: all .25s ease 0s;
   }
   &:active{
    box-shadow: inset 0 0 0 4px rgba(0,0,0,0.2);
   }  
    
}
.taskDesc{
  padding-top: 10px;
  width:75%;
  height:100%;
  text-align: left;
}
.leftpanel{
  font-size: 15px;
  padding: 5px;
  background-color:#d2d6d9;
  border-top : 2px solid #b3c4c2;
  border-left: 2px solid #b3c4c2;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px 24px 10px 20px ;
  box-shadow:2px 2px 2px #485660;
}
.projectname{
  font-size: 15px;
  padding: 0px 5px 0px 5px;
  background-color:#93a0aa;
  border:2px solid #93a0aa;
  border-radius: 5px 20px 20px 5px;
  box-shadow:2px 2px 2px #485660;
  font-weight: bold;
}
.taskName{
  font-size: 15px;
  font-weight: bold;
  font-style:italic;
  background-color:#96aeaa;
  border:2px solid #96aeaa;
  border-radius:5px 20px 20px 5px;
  box-shadow:2px 2px 2px #485660;
  font-weight: bold;
  padding: 0 5px 0 5px
}
.taskDetails{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  width:200px
  
}
