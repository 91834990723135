.displaysearchselectsdiv{
    padding-top:10px;
    margin:0;
    position:fixed;
    width:100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    padding-left:10px;
    
    
    z-index:999;
}