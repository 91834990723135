.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:none;
  padding: 1rem;
  border: 1px solid #ccc;
  height: 200px;
}
.toolbar-class {
  border: 1px solid #ccc;
}
