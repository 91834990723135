
.main{
display:flex;
flex-direction:column;
justify-content:center;
align-content:center;
height:100vh;
background-color:rgb(129, 124, 124)
}
.logo{
display:flex;
justify-content:center;
align-content:center;
width:100vw;
padding-right:0px
 }
 .text{
  display:flex;
justify-content:center;
align-content:center;
  height:100px;
  font-size:40px;
  color:black;
  padding-top: 10px;
  padding-left:350px;
  font-style: italic;
 }
 .loginform{
  display:flex;
justify-content:center;
align-content:center;
width:100vw;

 }